
:root {
  --error-bg: #d01f1f;
  --body-bg: #fdefc8;
  --body-font-size: 20px;
  --content-width: 1170px;
  --container-offset: clamp(21px, 2.0513vw, 24px);
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --scrollbar-thumb: #502a0c;
  --scrollbar-track: #fdefc8;
  --scrollbar-track-hover: transparent;
  --font-family: "BasisGrotesquePro", sans-serif;
  --second-family: "sf-pro-display", sans-serif;
  --cl-a: 255, 255, 255;
  --cl-b: 0, 0, 0;
  --cl-c: 83, 42, 13;
  --cl-d: 80, 42, 12;
  --cl-e: 80, 42, 14;
  --cl-f: 197, 164, 109;
  --bg-a: 255, 255, 255;
  --bg-b: 0, 0, 0;
  --bg-c: 217, 186, 131;
  --bg-d: 253, 217, 157;
  --bg-e: 253, 239, 200;
  --br-a: #f2d19d;
  --br-b: #fdd99d;
  --gr-a: linear-gradient(180deg, #ffb304 0%, #e28b05 100%);
  --gr-b: linear-gradient(180deg, #fdeac5 0%, #fdda9b 100%);
  --gr-c: linear-gradient(180deg, #4c3605 0%, #47350d 100%)
}

.progress {
  background: rgba(var(--bg-c), 1);
  border-radius: 8px;
  width: 100%;
  height: 30px;
  position: relative
}

.progress__line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  max-width: 100%;
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  background: var(--gr-a)
}

.subtitle {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  color: rgba(var(--cl-c), 1)
}

.title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 21px;
  color: rgba(var(--cl-c), 1)
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100dvw;
  will-change: font-size;
  font-size: var(--rem-function)
}

body {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  will-change: font-size;
  color: rgba(var(--cl-a), 1);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%
}

body.fixed-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden
}

main {
  min-width: 0 !important
}

main.no-padding {
  padding: 0
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

* {
  -webkit-tap-highlight-color: transparent
}

a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer
}

button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: 0;
  cursor: pointer
}

li,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none
}

a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
  color: rgba(var(--cl-c), 1);
  font-family: var(--font-family);
  font-style: normal;
  line-height: 100%
}

p {
  line-height: 130%;
  font-weight: 400;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

img {
  max-width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover
}

label,
picture {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: 0
}

input::-webkit-input-placeholder {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit
}

input::-moz-placeholder {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit
}

input:-ms-input-placeholder {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit
}

input::-ms-input-placeholder {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit
}

input::placeholder {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none
}

textarea {
  border: none;
  outline: 0;
  resize: none
}

fieldset {
  margin: 0;
  padding: 0;
  border: none
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%)
}

.container {
  position: relative;
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--container-offset)
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out;
  transition: background-color 5000s ease-in-out;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(0, 174, 255, 0) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0) 100%)
}

.panel,
[data-content] {
  z-index: 20;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .2s;
  transition: max-height .2s
}

[data-inner-content],
[data-tab-content] {
  display: none
}

[data-inner-content].active,
[data-tab-content].active {
  display: block
}

.dis-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  -ms-scroll-chaining: none !important;
  overscroll-behavior: none !important;
  scroll-behavior: none !important
}

.overlay {
  position: fixed;
  height: 100%;
  min-height: 100dvh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  padding: 34px 0;
  overflow-y: auto;
  background: rgba(var(--bg-f), 1);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 1s .5s, visibility 1s .5s;
  transition: opacity 1s .5s, visibility 1s .5s;
  pointer-events: none
}

.overlay::-webkit-scrollbar {
  display: none
}

.overlay.active {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity .6s, visibility .6s;
  transition: opacity .6s, visibility .6s;
  pointer-events: initial
}

.overlay.active-mode {
  z-index: 100;
  visibility: visible;
  opacity: 1;
  background: rgba(var(--bg-f), 1);
  -webkit-transition: opacity .3s, visibility .3s;
  transition: opacity .3s, visibility .3s;
  pointer-events: initial
}

::-webkit-scrollbar {
  width: 8px;
  cursor: pointer
}

@media (max-width:768px) {
  ::-webkit-scrollbar {
    display: none
  }
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  cursor: pointer;
  -webkit-transition: .2s;
  transition: .2s
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track)
}

::-webkit-scrollbar-button {
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.emplty_list {
  text-align: center;
  margin-top: 30px
}

.copy_input {
  font-size: 18px;
  width: 100%
}

.copy_but {
  background-color: #c5a46d;
  padding: 5px;
  border-radius: 7px
}

.withdraw_result.success {
  padding: 10px;
  border-radius: 7px;
  text-align: center;
  background-color: #069c12
}

.withdraw_result.error {
  padding: 10px;
  border-radius: 7px;
  text-align: center;
  background-color: #b3051c
}

.referral-card p {
  font-size: 13px
}

.partners_pag,
.tasks-list__item .task_claimed_icon {
  display: none
}

.tasks-list__item.claimed .task_claimed_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end
}

.tasks-list__item.claimed .task_button {
  display: none
}

.main-btn {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  background: var(--gr-a);
  height: 40px;
  padding: 10px 50px;
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 14px;
  color: rgba(var(--cl-e), 1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: .2s;
  transition: .2s
}

.main-btn:hover {
  opacity: .9;
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  -webkit-box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951
}

.main-btn--mode {
  color: rgba(var(--cl-a), 1);
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25);
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25);
  background: var(--gr-c)
}

.main-btn--mode:hover {
  -webkit-box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .25);
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .25)
}

.copy-btn {
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  background: var(--gr-a);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: .2s;
  transition: .2s
}

.copy-btn:hover {
  opacity: .9;
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  -webkit-box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .25), inset 0 1px 0 0 #feb951
}

.main-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-gap: 4px
}

.main-referral {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 22px
}

.main-referral__box {
  -webkit-box-shadow: 0 4px 0 0 #d9ba83;
  box-shadow: 0 4px 0 0 #d9ba83;
  background: var(--gr-b);
  padding: 20px 24px 24px;
  border: 1px solid var(--br-a);
  border-radius: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 7px
}

.main-referral__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px
}

.page-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 8px;
  -ms-grid-row-align: center;
  align-self: center
}

.page-nav__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--br-b);
  border-radius: 4px;
  background: rgba(var(--bg-e), 1);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  text-align: center;
  color: rgba(var(--cl-c), 1);
  -webkit-transition: .3s;
  transition: .3s
}

.page-nav__btn.active {
  font-weight: 400
}

.page-nav__btn:disabled {
  background-color: rgba(var(--bg-d), 1);
  opacity: .5;
  pointer-events: none
}

.page-nav__btn:hover {
  background-color: rgba(var(--bg-d), 1);
  opacity: .8
}

.page-nav__btn--prev svg {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1)
}

.main-form {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 4px 0 0 #d9ba83;
  box-shadow: 0 4px 0 0 #d9ba83;
  background: var(--gr-b);
  border: 1px solid var(--br-a);
  border-radius: 21px;
  grid-gap: 12px;
  padding: 15px 33px 18px 36px
}

.main-form__icon {
  z-index: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 12px;
  pointer-events: none;
  width: 24px;
  height: 24px
}

.main-form__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 4px
}

.main-form__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 12px
}

.main-form .subtitle {
  line-height: 131%
}

.main-form__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  grid-gap: 6.5px;
  border-radius: 8px;
  background: rgba(var(--bg-c), 1);
  -webkit-transition: .3s;
  transition: .3s
}

.main-form__label--mode .main-form__input {
  padding: 8px 12px 8px 43px
}

.main-form__input {
  padding: 8px 12px;
  height: 40px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.main-form__input::-webkit-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.main-form__input::-moz-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.main-form__input:-ms-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.main-form__input::-ms-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.main-form__input::placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.main-form p {
  font-weight: 500
}

.main-form--mode {
  padding: 19px 22px 10px 23px
}

.invite {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 8px;
  padding: 19px 24px 23px;
  -webkit-box-shadow: 0 4px 0 0 #d9ba83;
  box-shadow: 0 4px 0 0 #d9ba83;
  background: var(--gr-b);
  border: 1px solid var(--br-a);
  border-radius: 21px
}

.invite__box {
  margin-top: 2px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 40px;
  grid-template-columns: 1fr 40px;
  grid-gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.invite__label {
  background: rgba(var(--bg-c), 1);
  border-radius: 8px;
  height: 40px;
  padding: 12px 8px 10px 16px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.invite__input {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1);
  text-align: start;
  max-width: calc(100% - 1px);
  text-overflow: ellipsis;
  overflow: hidden
}

.invite__input::-webkit-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1);
  text-align: start
}

.invite__input::-moz-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1);
  text-align: start
}

.invite__input:-ms-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1);
  text-align: start
}

.invite__input::-ms-input-placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1);
  text-align: start
}

.invite__input::placeholder {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-c), 1);
  text-align: start
}

.main-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-shadow: 0 4px 0 0 #d9ba83;
  box-shadow: 0 4px 0 0 #d9ba83;
  background: var(--gr-b);
  border: 1px solid var(--br-a);
  border-radius: 21px;
  padding: 21px 30px
}

.main-info__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 10px
 
}

.main-info__value {
  font-weight: 900;
  font-size: 22px;
  color: rgba(var(--cl-c), 1)
}

.main-info__value b {
  font-weight: 900
}

.main-info__mining {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 5px
}

.main-info__currency {
  font-weight: 500;
  font-size: 16px;
  color: rgba(var(--cl-d), 1)
}

.main-info__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 12px;
  grid-gap: 10px;
  width: 100%
}

.main-info__btns .main-btn {
  width: 100%
}

.main-info--mode {
  grid-gap: 10px;
  padding: 21px 30px 7px
}

.main-info--mode .main-btn {
  margin-bottom: 3px;
  width: 100%;
  max-width: 576px
}

.main-info--mode p {
  font-weight: 500
}

.history-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.history-list__row {
  padding: 11px 13px 11px 9px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.history-list__row:not(.history-list__row--head) {
  height: 40px;
  border-radius: 4px
}

.history-list__row--head {
  padding: 0 13px 5px 9px
}

.history-list__row:nth-child(even) {
  background-color: rgba(var(--bg-d), 1)
}

.history-list__row .history-list__name:last-child,
.history-list__row .history-list__value:last-child {
  text-align: end
}

.history-list__name {
  color: rgba(var(--cl-b), 1);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 130%
}

.history-list__value {
  line-height: 130%;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(var(--cl-c), 1)
}

.history-list__value b {
  font-weight: 500
}

.history-list--wallet .history-list__row {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr
}

.tasks-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 17px;
  width: 100%
}

.steps-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 9px;
  counter-reset: li
}

.steps-list__item {
  width: 100%;
  counter-increment: li
}

.referral-card {
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 3px;
  background: rgba(var(--bg-c), 1);
  padding: 9px 8px 10px 15px
}

.referral-card__value {
  font-weight: 700;
  font-size: 21px;
  color: rgba(var(--cl-c), 1)
}

.referral-card__value b {
  font-size: 14px
}

.task-card {
  -webkit-box-shadow: 0 4px 0 0 #d9ba83;
  box-shadow: 0 4px 0 0 #d9ba83;
  background: var(--gr-b);
  border: 1px solid var(--br-a);
  border-radius: 12px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 44px 1fr .39fr;
  grid-template-columns: 44px 1fr .39fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 15px;
  padding: 8px 13px 8px 11px
}

.task-card__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 2px
}

.task-card__title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  color: rgba(var(--cl-c), 1)
}

.task-card__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(var(--bg-c), 1);
  border-radius: 12px;
  width: 44px;
  height: 44px
}

.task-card__mining {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 5px
}

.task-card__mining-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px
}

.task-card__mining-icon img,
.task-card__mining-icon svg {
  width: 100%;
  height: 100%
}

.task-card__value {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(var(--cl-d), 1);
  line-height: 129%
}

.task-card__value b {
  font-weight: 500
}

.task-card .main-btn {
  padding: 10px 20px
}

.step-card {
  border: 1px solid var(--br-a);
  border-radius: 21px;
  height: 60px;
  background: var(--gr-b);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 10px;
  padding: 10px 19px;
  width: 100%
}

.step-card::before {
  content: "0" counter(li);
  font-family: var(--font-family);
  font-weight: 900;
  font-size: 35px;
  color: rgba(var(--cl-f), 1);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 100%
}

.step-card p {
  font-weight: 500
}

.withdraw-section {
  padding: 7px 0 116px;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.withdraw-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(var(--container-offset) * -1);
  right: calc(var(--container-offset) * -1);
  height: 71px;
  background: rgba(var(--bg-d), 1);
  pointer-events: none;
  z-index: -1
}

.withdraw-section .container {
  min-height: 100%
}

.withdraw-section__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100dvh - 116px - 7px);
  grid-gap: 18px;
  padding-bottom: 15px
}

.withdraw-section__box .history-list {
  margin-top: 2px
}

.first-section {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 35dvh 0 9.2dvh;
  height: 100vh
}

.first-section .container {
  height: 100%
}

.first-section__box {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 3
}

.start-section {
  overflow: hidden;
  padding: 7px 0 116px;
  min-height: 100dvh
}

.start-section .container {
  min-height: 100%
}

.start-section__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100dvh - 116px - 7px);
  position: relative
}

@media (min-width:576px) {
  .start-section__box {
    grid-gap: clamp(18px, 2.5391vw, 26px)
  }
}

@media (max-width:576px) {
  .start-section__box {
    grid-gap: 18px
  }
}

.start-section .mining {
  z-index: -1;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: calc(var(--container-offset) * -1);
  width: 100vw
}

.start-section .mining video {
  width: 100vw;
  height: 100%
}

@media (min-height:740px) {
  .start-section .mining video {
    height: calc(100svh - 116px - 57px);
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 70% 50%;
    object-position: 70% 50%
  }
}

.start-section .mining::after,
.start-section .mining::before {
  -webkit-filter: blur(42.7999992371px);
  filter: blur(42.7999992371px);
  background: rgba(var(--bg-d), 1);
  position: absolute;
  content: "";
  width: 150%;
  z-index: 2;
  height: 100%;
  pointer-events: none
}

.start-section .mining::before {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 95%);
  -ms-transform: translate(-50%, 95%);
  transform: translate(-50%, 95%)
}

.start-section .mining::after {
  -webkit-transform: translate(-50%, -87%);
  -ms-transform: translate(-50%, -87%);
  transform: translate(-50%, -87%);
  top: 0;
  left: 50%
}

.wallet-section {
  padding: 7px 0 116px;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.wallet-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(var(--container-offset) * -1);
  right: calc(var(--container-offset) * -1);
  height: 71px;
  background: rgba(var(--bg-d), 1);
  pointer-events: none;
  z-index: -1
}

.wallet-section .container {
  min-height: 100%
}

.wallet-section__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 15px;
  min-height: calc(100dvh - 116px - 7px);
  grid-gap: 18px
}

.wallet-section__box .history-list {
  margin-top: 2px
}

.referral-section {
  padding: 18px 0 116px;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.referral-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(var(--container-offset) * -1);
  right: calc(var(--container-offset) * -1);
  height: 175px;
  background: rgba(var(--bg-d), 1);
  pointer-events: none;
  z-index: -1
}

.referral-section .container {
  min-height: 100%
}

.referral-section__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100dvh - 116px - 18px);
  grid-gap: 24px;
  padding-bottom: 15px
}

.referral-section__wrapp {
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 18px
}

.referral-section__wrapp .history-list {
  margin-top: 8px
}

.referral-section--mode::before {
  height: 75px
}

.task-section {
  padding: 18px 0 116px;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.task-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(var(--container-offset) * -1);
  right: calc(var(--container-offset) * -1);
  height: 127px;
  background: rgba(var(--bg-d), 1);
  pointer-events: none;
  z-index: -1
}

.task-section .container {
  min-height: 100%
}

.task-section .main-top .title {
  line-height: 128%
}

.task-section__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100dvh - 116px - 18px);
  grid-gap: 27px;
  padding-bottom: 15px
}

.task-section__box .history-list {
  margin-top: 2px
}

.boost-section {
  padding: 18px 0 116px;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
  z-index: 1
}

.boost-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(var(--container-offset) * -1);
  right: calc(var(--container-offset) * -1);
  height: 177px;
  background: rgba(var(--bg-d), 1);
  pointer-events: none;
  z-index: -1
}

.boost-section .container {
  min-height: 100%
}

.boost-section .main-top .title {
  line-height: 128%
}

.boost-section__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100dvh - 116px - 18px);
  grid-gap: 18px;
  padding-bottom: 15px
}

.boost-section__box .steps-list {
  margin-top: 10px
}

.bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 60;
  height: 116px;
  border-radius: 10px 10px 0 0;
  background-color: rgba(var(--bg-d), 1);
  padding: 17px var(--container-offset) 19px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px
}

.bar__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: currentColor;
  width: 30px;
  height: 30px
}

.bar__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.bar__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-gap: 5px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(var(--cl-c), 1);
  -webkit-box-shadow: inset 0 -4px 0 0 #eee8e8;
  box-shadow: inset 0 -4px 0 0 #eee8e8;
  background: rgba(var(--bg-a), 1);
  border-radius: 21px;
  -webkit-transition: .2s;
  transition: .2s
}

.bar__link:hover {
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  -webkit-box-shadow: inset 0 -3px 0 0 #eee8e8;
  box-shadow: inset 0 -3px 0 0 #eee8e8
}

td{
  padding: 1px;
  border: 1px solid black;
}


/* toastcss */
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

/* css for withdraw */

.custom-shadow {
  box-shadow: 0 4px 0 0 #d9ba83;
}
.bg-primary {
  background-color: #ffecd2; /* Light yellow background */
}
.bg-card {
  background-color: #fff4d4; /* Light yellow background for card */
}
.bg-input {
  background-color: #ffffff; /* Light yellow background for input */
}
.bg-withdraw {
  background-color: #f59e0b; /* Orange background for Withdraw button */
}
.text-primary {
  color: #4a3608; /* Dark gray text */
}
.text-secondary {
  color: #f59e0b; /* Orange text */
}

